@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --nav-height: 64px;

    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 116 53% 45%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.c-flex {
  @apply flex items-center justify-center;
}

.s-flex {
  @apply flex items-center justify-start;
}

.e-flex {
  @apply flex items-center justify-end;
}

.b-flex {
  @apply flex items-center justify-between;
}
/*美化所有滚动条*/
::-webkit-scrollbar {
  @apply w-[10px];
}

::-webkit-scrollbar-thumb {
  @apply rounded-full bg-[#dbdbdb];
}

.home-banner2 .swiper-pagination {
  @apply !left-1/2 flex -translate-x-1/2 items-center justify-center gap-2;
}
.home-banner2 .swiper-pagination-bullets {
  bottom: 48px !important;
  z-index: 3;
}
.home-swiper-pagination-bullet {
  height: 2px;
  width: 16px !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  overflow: hidden !important;
}
.home-swiper-pagination-bullet-active {
  /* 将激活的分页按钮颜色设置为黑色 */
  /* background-color: black !important;  */
  /* @apply !bg-main; */
  background-color: rgba(255, 255, 255, 0.3) !important;
  position: relative;
}
.home-swiper-pagination-bullet-active::after {
  @apply !bg-white;
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  animation: banner-bullet-fill 7s linear forwards;
}

@keyframes banner-bullet-fill {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.next-enter {
  animation: next 1s ease;
}
@keyframes next {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.next-leave {
  animation: pre 1s ease;
}
@keyframes pre {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.pre-enter {
  animation: pre-enter 1s ease;
}
@keyframes pre-enter {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pre-leave {
  animation: pre-leave 1s ease;
}
@keyframes pre-leave {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}

.store-swiper .swiper-pagination {
  @apply !left-1/2 flex -translate-x-1/2 items-center justify-center gap-0;
}
.store-swiper .swiper-pagination-bullets {
  bottom: 24px !important;
  z-index: 3;
}
.store-swiper-pagination-bullet {
  height: 2px;
  width: 16px !important;
  background-color: rgba(0, 0, 0, 0.3);
}
.store-swiper-pagination-bullet-active {
  /* 将激活的分页按钮颜色设置为黑色 */
  /* background-color: black !important;  */
  /* @apply !bg-main; */
  background-color: rgba(0, 0, 0, 1) !important;
}

.input-label {
  /* absolute z-0 top-1/2 -translate-y-1/2 px-3 py-2 text-[#999] focus:top-0 */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* padding:  0.5rem 0.75rem; */
  left: 16px;
  color: #999;
  transition: all 0.2s;
  font-size: 14px;
}
.input-label:focus,
.input-container:focus + .input-label,
.input-container:not(:placeholder-shown) + .input-label {
  top: 0;
  left: 0;
  transform: translateY(-100%);
  color: black;
  font-size: 12px;
  /* background-color: #fff; */
  @apply text-main;
}

.p10-banner .swiper-pagination {
  @apply -top-10 bottom-auto z-10 flex h-3 w-full items-center justify-center gap-2;
}
.p10-banner-pack .swiper-pagination {
  @apply bottom-auto top-20 z-10 flex h-3 w-full items-center justify-center gap-2 md:top-40;
}
.p10-banner .p10-swiper-pagination-bullet,
.p10-banner-pack .p10-swiper-pagination-bullet {
  background-color: #cbcbcb;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.p10-banner .p10-swiper-pagination-bullet-active,
.p10-banner-pack .p10-swiper-pagination-bullet-active {
  background-color: #313131;
}

.p10-banner-2 .swiper-pagination {
  @apply !left-5 bottom-auto top-[100px] z-10 flex h-3 !w-auto items-center justify-center gap-2 md:!left-10 md:top-[130px] 2xl:!left-20 2xl:top-[260px];
}

.p10-banner-3 .swiper-pagination {
  @apply !left-5 !top-[60px] bottom-auto z-10 flex h-3 !w-auto items-center justify-center gap-2 md:!left-10 md:!top-[120px] md:ml-10;
}

.p10-banner-4 .swiper-pagination,
.p10-banner-6 .swiper-pagination {
  @apply !left-5 bottom-auto top-24 z-10 flex h-3 !w-auto items-center justify-center gap-2 md:!left-10 md:top-32 md:ml-10 2xl:top-52;
}

.p10-banner-2 .p10-swiper-pagination-bullet,
.p10-banner-3 .p10-swiper-pagination-bullet,
.p10-banner-4 .p10-swiper-pagination-bullet,
.p10-banner-6 .p10-swiper-pagination-bullet {
  background-color: #8f9094;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.p10-banner-2 .p10-swiper-pagination-bullet-active,
.p10-banner-3 .p10-swiper-pagination-bullet-active,
.p10-banner-4 .p10-swiper-pagination-bullet-active,
.p10-banner-6 .p10-swiper-pagination-bullet-active {
  background-color: #fff;
}
#descDoc img {
  /* @apply w-full; */
  vertical-align: inherit;
  display: inherit;
}
#descDoc ul {
  /* @apply mb-5 mt-2 grid grid-cols-2 gap-5 border-t max-md:grid-cols-1; */
}
#descDoc ul li span {
  @apply text-main;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-slider {
  -webkit-appearance: none; /* 清除默认样式 */
  height: 8px; /* 滑轨高度 */
  background: #333; /* 滑轨背景颜色 */
  border-radius: 25px;
  outline: none; /* 去掉默认的outline */
  opacity: 0.7; /* 不透明度 */
  transition: opacity 0.2s; /* 过渡效果 */
  opacity: 0.7;
}

.custom-slider:hover {
  opacity: 1; /* 悬停时提高不透明度 */
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* 清除默认样式 */
  appearance: none; /* 清除默认样式 */
  width: 20px; /* 滑块宽度 */
  height: 20px; /* 滑块高度 */
  background: #4caf50; /* 滑块背景颜色 */
  cursor: pointer; /* 鼠标指针样式 */
  border-radius: 50%; /* 滑块圆角 */
}

.custom-slider::-moz-range-thumb {
  width: 20px; /* 滑块宽度 */
  height: 20px; /* 滑块高度 */
  background: #4caf50; /* 滑块背景颜色 */
  cursor: pointer; /* 鼠标指针样式 */
  border-radius: 50%; /* 滑块圆角 */
}

.custom-height {
  height: calc(50vw + var(--nav-height));
}

@media (min-width: 768px) {
  .custom-height {
    height: calc(30vw + var(--nav-height));
  }
}

@media (min-width: 1536px) {
  .custom-height {
    height: calc(20vw + var(--nav-height));
  }
}

@media (max-width: 767px) {
  .custom-height {
    height: calc(200px + var(--nav-height));
  }
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  @apply border-none !bg-main !text-main;
}

.custom-checkbox .ant-checkbox-wrapper {
  @apply !m-0;
}
.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply !text-main;
}
.custom-tabs .ant-tabs-tab {
  @apply hover:!text-main;
}
.custom-tabs .ant-tabs-ink-bar {
  @apply !bg-main;
}
.ant-pagination-item-active {
  @apply !border-main;
  & a {
    @apply !text-main;
  }
}
.custom-form .ant-form-item .ant-input-outlined:focus-within {
  @apply border-main;
}
.custom-form .ant-form-item .ant-input-outlined:hover {
  @apply border-main;
}
input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0; /* 为了在Firefox中去除默认的内边距 */
}

/* 对于某些浏览器，还需要额外指定 */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* 时间线 */
.historyMain {
  position: relative;
  min-height: 100vh;
  padding: 100px 20px;
  background-image: url("/image/about/history-bg.jpg");
  /* background-color: #808080; */
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.historyMain::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #3b6de32b; 可以根据需要调整遮罩颜色和透明度#3b6de32b */
  z-index: 1; /* 确保遮罩层在背景图片之上 */
}
.historyMain > * {
  position: relative;
  z-index: 2; /* 确保内容在遮罩层之上 */
}
.historyYearBox {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.historyYear .swiper-container {
  overflow: visible;
}
.historyYearBox.fixed {
  position: fixed; /* Fixed position when in view */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.historyYearBox.absolute {
  position: absolute; /* Absolute position near the bottom */
  bottom: 0 !important;
  top: auto;
  left: 0;
  width: 100%;
  height: 100vh;
}

.historyYearBox .historyContainer .historyYearListBox {
  width: 40%;
  position: relative;
}

.historyYearBox .historyContainer .historyYear {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 240px;
  height: 720px;
  overflow: hidden;
  position: relative;
}

.historyYearBox .historyContainer .historyYear .Year {
  color: #fff;
  line-height: 240px;
  z-index: 2;
  position: relative;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.historyYearBox .historyContainer .historyYear .YearList {
  line-height: 240px;
  height: 240px;
  position: relative;
  z-index: 2;
}

.historyYearBox .historyContainer .historyYear .YearList .swiper-slide {
  color: rgba(240, 246, 244, 0.1);
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.historyYearBox .historyContainer .historyYear .YearList .swiper-slide.swiper-slide-active {
  /* color: #015291 */
  @apply text-main;
}
.YearList.swiper-container {
  margin: 0;
}
.historyYearBox .historyContainer .roundBox {
  position: absolute;
  width: 440px;
  height: 440px;
  right: -22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 100%;
}

.historyYearBox .historyContainer .roundBox svg {
  position: absolute;
  left: -18px;
  top: -18px;
  right: -18px;
  bottom: -18px;
}

.historyYearBox .historyContainer .roundBox svg .path-loop {
  fill: none;
  stroke: rgba(240, 246, 244, 0.1);
  stroke-miterlimit: 10;
  stroke-width: 0.1px;
}

.historyYearBox .historyContainer .roundBox svg .path-loop1 {
  fill: none;
  /* stroke: #015291; */
  stroke: #3eb036;
  stroke-miterlimit: 10;
  stroke-width: 0.1px;
  stroke-dashoffset: 157;
  -webkit-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.historyListMain {
  position: relative;
  z-index: 3;
}

.historyListMain .historyContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.historyListMain .historyContainer .historyList {
  width: 100%;
}

.historyListMain .historyContainer .historyList ul li {
  margin-top: 15px;
  padding: 0 0 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.historyListMain .historyContainer .historyList ul li .imgBox {
  padding-top: 20px;
}

.historyListMain .historyContainer .historyList ul li .imgBox img {
  width: 100%;
  display: block;
  border-radius: 20px;
}

.historyListMain .historyContainer .historyList ul li:last-child {
  border-bottom: none;
}

@media (min-width: 768px) {
  .historyYearBox .historyContainer .historyYear {
    font-size: 15vw;
    height: 45vw;
  }
  .historyYearBox .historyContainer .historyYear .Year {
    line-height: 15vw;
  }

  .historyYearBox .historyContainer .historyYear .YearList {
    line-height: 15vw;
    height: 15vw;
  }

  .historyYearBox .historyContainer .roundBox {
    width: 27.5vw;
    height: 27.5vw;
    right: -1.375vw;
  }

  .historyYearBox .historyContainer .roundBox svg {
    left: -1.125vw;
    top: -1.125vw;
    right: -1.125vw;
    bottom: -1.125vw;
  }

  .historyListMain .historyContainer .historyList {
    width: 100%;
    padding: 3.125vw 0;
  }

  .historyListMain .historyContainer .historyList ul li {
    margin-top: 2.188vw;
    padding: 0 0 2.188vw;
    border-bottom: 0.063vw solid rgba(255, 255, 255, 0.3);
  }

  .historyListMain .historyContainer .historyList ul li .listYear {
    font-size: 1.5vw;
    padding-bottom: 0.625vw;
  }

  .historyListMain .historyContainer .historyList ul li .desc {
    font-size: 1vw;
    line-height: -webkit-calc(22 / 16);
    line-height: calc(22 / 16);
  }

  .historyListMain .historyContainer .historyList ul li .imgBox {
    padding-top: 1.25vw;
  }

  .historyListMain .historyContainer .historyList ul li .imgBox img {
    border-radius: 1.25vw;
  }
}

.historyListMain .historyContainer .historyList ul li .listYear {
  font-size: 18px;
  line-height: 1.2;
  padding-bottom: 10px;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.historyListMain .historyContainer .historyList ul li .desc {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

@media (min-width: 1200px) {
  .historyListMain .historyContainer .historyList .removeStyle li .desc {
    font-size: 1.4rem;
  }
}
@media (min-width: 992px) {
  .historyYearBox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .historyListMain .historyContainer .historyList {
    width: 33.313vw;
    padding-top: 45vh;
    padding-bottom: 26vw;
  }

  .historyListMain .historyContainer .historyList ul li .listYear {
    color: rgba(255, 255, 255, 0.3);
  }

  .historyListMain .historyContainer .historyList ul li .desc {
    color: rgba(255, 255, 255, 0.3);
  }

  .historyListMain .historyContainer .historyList ul li.cur .listYear {
    color: #fff;
  }

  .historyListMain .historyContainer .historyList ul li.cur .desc {
    color: #fff;
  }

  .historyYearBox .historyContainer .historyYear {
    font-size: 10vw;
    height: 37.5vw;
  }

  .historyYearBox .historyContainer .historyYear .Year {
    line-height: 12.5vw;
  }

  .historyYearBox .historyContainer .historyYear .YearList {
    line-height: 12.5vw;
    height: 12.5vw;
  }

  .historyYearBox .historyContainer .roundBox {
    width: 22.917vw;
    height: 22.917vw;
    right: -1.146vw;
  }

  .historyYearBox .historyContainer .roundBox svg {
    left: -0.938vw;
    top: -0.938vw;
    right: -0.938vw;
    bottom: -0.938vw;
  }

  .historyListMain .historyContainer .historyList {
    width: 40vw;
    padding-bottom: 10vw;
  }

  .historyListMain .historyContainer .historyList ul li {
    margin-top: 1.823vw;
    padding: 0vw 0vw 1.823vw;
    border-bottom: 0.052vw solid rgba(255, 255, 255, 0.3);
  }

  .historyListMain .historyContainer .historyList ul li .listYear {
    font-size: 2vw;
    padding-bottom: 0.521vw;
  }

  .historyListMain .historyContainer .historyList ul li .desc {
    font-size: 1.3vw;
  }

  .historyListMain .historyContainer .historyList ul li .imgBox {
    padding-top: 1.042vw;
  }

  .historyListMain .historyContainer .historyList ul li .imgBox img {
    border-radius: 1.042vw;
  }
}
.historyContainer {
  max-width: 80vw;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 2000px) {
  .historyContainer {
    max-width: 70vw;
  }
  .historyListMain .historyContainer .historyList {
    width: 35vw;
  }
}
@media (max-width: 1800px) {
  .historyContainer {
    max-width: 70vw;
  }
  .historyListMain .historyContainer .historyList {
    width: 35vw;
  }
}
@media (max-width: 576px) {
  .historyListMain .historyContainer .historyList {
    width: 100vw;
  }
}
.review-item:first-child {
  border-top: none !important;
}
.fi-en {
  background-image: url("/image/language/en.svg");
}
.fi-ja {
  background-image: url("/image/language/ja.svg");
}
.fi-ko {
  background-image: url("/image/language/ko.svg");
}
.fi-hi {
  background-image: url("/image/language/hi.svg");
}
.custom-tabs .ant-tabs-tab-btn {
  font-size: 20px;
}
/* .custom-tabs .ant-tabs-nav-wrap {
  padding-left: 40px;
} */
.custom-tabs .ant-tabs-ink-bar {
  width: 0 !important;
}
/* .custom-tabs  .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
} */
.custom-tabs .ant-tabs-nav::before {
  border-bottom-color: #d7d7d7;
}
.custom-tabs .review-item {
  @apply md:pl-10;
}
.custom-tabs .ant-tabs-tab {
  @apply md:!ml-14;
}
.rft .ant-rate-star-full {
  margin-inline-end: 3px !important;
}
@font-face {
  font-family: "Regular";
  src: url("/font/OpenSans-Regular.woff") format("woff");
  font-weight: 500;
  /* Medium */
  font-style: normal;
}
@font-face {
  font-family: "Regular-blod";
  src: url("/font/OpenSans-Semibold.woff") format("woff");
  font-weight: 700;
  /* Medium */
  font-style: normal;
}
.font-bold {
  font-family: "Regular-blod",sans-serif; /* 加粗字体 */
}
body {
  font-family: 'Regular', sans-serif; /* 默认使用 Regular 字体 */
}

.features-wrap ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}
.features-wrap dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}
.features-wrap ol {
  display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}